import React, { Component } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import IntroMain from "../contents/intro/IntroMain";
import ContectMain from "../contents/contect/ContectMain";
import ActivityAreaMain from "../contents/activity_area/ActivityAreaMain";
import ActivityMain from "../contents/activity/ActivityMain";
import Footer from "./Footer";



class Contents extends Component {
  render() {
    return (
      <div>
        <Routes>
          <Route path="intro" element={<IntroMain />} />
          <Route path="activity" element={<ActivityMain />} />
          <Route path="activity_area" element={<ActivityAreaMain />} />
          <Route path="contect" element={<ContectMain />} />
          <Route path="/" element={<Navigate replace to="/intro" />} />
        </Routes>
        <Footer/>
      </div>
      
    );
  }
}

export default Contents;
