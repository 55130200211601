import React, { Component } from 'react';
import { FullPage, Slide } from "react-full-page";
import Footer from '../../layout/Footer';
import History from './views/History';
import OrganizationChart from './views/OrganizationChart';

class ActivityMain extends Component {
  render() {
    return (
      <div className="full-page-area about">
         <History/>
         <OrganizationChart/>
      {/* <FullPage controls>
        <Slide>
          <History/>
        </Slide>
        <Slide>
          <OrganizationChart/>
        </Slide>
      </FullPage> */}
    </div>
    );
  }
}

export default ActivityMain;