import React, { Component } from "react";

class History extends Component {
  render() {
    return (
      <div className="history01">
        <div className="title" style={{ marginBottom: "0px" }}>
          <h2>발자취</h2>
        </div>
        <div className="contents">
            <div class="history_info">
              <div class="history_info01">
                <div class="history_info01_ri">
                  <div class="his_con">
                    <ul>
                    <li>
                        <p class="year">2023</p>
                        <ul>
                          <li>
                            <span>대통령직속 국가균형발전위원회 연구 수행</span>
                            '부모의 자본과 경험이 청년의 미래 전망에 미치는 영향 연구'
                          </li>
                          <li>
                            <span>청년재단 연구 수행</span>
                            '수도권 대학 진학에 대한 영향요인 연구'
                          </li>
                          <li>
                            <span>소셜벤처 로컬캐스트 연구 수행</span>
                            '청년성장여행 쏠쏠캠프 효과성 검증 연구'
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p class="year">2022</p>
                        <ul>
                          <li>
                            <span>서울청년센터 마포오랑 연구 수행</span>
                            ‘마포구 청년 욕구 조사연구’
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p class="year">2021</p>
                        <ul>
                          <li>
                            <span>국회의원연구단체 연구 수행</span>
                            ‘창업 인식조사를 통한 창업활성화 방안 마련’
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p class="year">2020</p>
                        <ul>
                          <li>
                            <span>서울시 청년청 연구 수행</span>
                            ‘청년이 생각하는 직업과 일자리 패러다임 변화 연구’
                          </li>
                          <li>
                            <span>청년재단 연구 수행</span>
                            ‘청년-기성세대 소통을 위한 상담 효과성 평가’
                          </li>
                          <li>
                            <span>
                              ‘청년, 편지로 어른을 만나다’ 상담 프로그램
                              프로젝트 수행
                            </span>
                          </li>
                          <li>
                            <span>‘청년, 편지로 어른을 만나다’ 도서 발간</span>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p class="year">2019</p>
                        <ul>
                          <li>
                            <span>경남연구원 연구 수행</span>
                            ‘경상남도 지역 청년 정책 사업의 효과성 검토 및 향후
                            방향 관련 연구’
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="history_info01_le">
                  <img src="assets/about_img05.png" alt="" />
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

export default History;
