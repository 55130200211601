import React, { Component } from "react";

class Introduction extends Component {
  render() {
    return (
      <div className="about02 h100">
        <div className="title">
          <h2>단체 소개</h2>
        </div>
        <div className="contents">
          <section>
            <div className="sub-title">
              <strong>스페셜 스페이스는,</strong>{" "}
            </div>
            <div className="part">
              청년연구단체 ‘스페셜 스페이스’는 궁극적으로 아름다운 사회를 만들기
              위해
              <br />
              현재 직면한 문제를 찾고, 연구하고, 새로운 대안을 만드는 사람들이
              모인 ‘특별한 공간’입니다.
            </div>
          </section>
          <section>
            <div className="sub-title">
              <strong>단체 목표 및 사업 요약</strong>
            </div>
            <div className="box-section">
              <ul>
                <li>
                  <div class="box">
                    <div class="box_img">
                      <img src="assets/about02_01.png" alt="" />
                    </div>
                    <div class="box_txt">청년 관련 프로젝트 기획 및 홍보</div>
                  </div>
                </li>
                <li>
                  <div class="box">
                    <div class="box_img">
                      <img src="assets/about02_02.png" alt="" />
                    </div>
                    <div class="box_txt">
                      청년 세대를 위한 연구과제 수행
                      <br />
                      (일자리, 주거, 인권, 공간 등)
                    </div>
                  </div>
                </li>
                <li>
                  <div class="box">
                    <div class="box_img">
                      <img src="assets/about02_03.png" alt="" />
                    </div>
                    <div class="box_txt">
                      더 나은 사회를 만들기 위한 인식 개선 홍보 및 프로젝트 수행
                    </div>
                  </div>
                </li>
                <li>
                  <div class="box">
                    <div class="box_img">
                      <img src="assets/about02_04.png" alt="" />
                    </div>
                    <div class="box_txt">
                      실험 형태의 프로젝트 수행을 통한 사회적 가치 실현 및 인식
                      개선, 정책 제안
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Introduction;
