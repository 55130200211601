import React, { Component } from "react";

class IntroduceCeo extends Component {
 render() {
  return (
   <div class="history01">
    <div class="title" style={{ marginBottom: "0px" }}>
     <h2>대표 소개</h2>
    </div>
    <div class="contents">
     <div class="brief_history_info">
      <div class="brief_history_info01">
       <div class="brief_history_info01_ri">
        <div class="brief_title">
         대표 유재은
         <div class="brief_sub_title">
          청년 이슈 연구 및 프로젝트 수행, 청년정책 자문 활동 및 강연 외 다수
         </div>
        </div>
        <div class="brief_his_con">
         <ul>
          <li>
           <p class="year">청년연구단체 스페셜 스페이스 대표</p>
          </li>
          <li>
           <p class="year">
            국무조정실 청년정책조정위원회, 기획재정부 중장기전략위원회,
            고용노동부 고용정책심의회, 교육부 글로컬대학위원회, 인사혁신처
            정책자문위원회 위원 등
           </p>
          </li>
          <li>
           <p class="year">(前) 대통령직속 청년위원회 연구원</p>
          </li>
          <li>
           <p class="year">정책학 박사(서울대학교)</p>
          </li>
         </ul>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
  );
 }
}

export default IntroduceCeo;
