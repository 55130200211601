import React, { Component } from "react";
import Footer from "../../../layout/Footer";

class Contect extends Component {
 render() {
  return (
   <div className="contect01">
    <div className="title">
     <h2>연락/문의</h2>
    </div>
    <div className="contents">
     <section>
      <div className="part">
       ‘더 나은 세상’을 만들기 위해, <br />
       ‘새로운 문화’를 창출하기 위한
       <br />
       ‘스페셜 스페이스’의 연구와 프로젝트는 계속됩니다.
      </div>
      <div className="part color-box">MAKE IT BETTER ‘SPACE’</div>
      <div className="part">
       누구나 특별할 수 있는 공간, <br />
       누구나 특별함을 만들 수 있는 공간
       <br />
       ‘스페셜 스페이스’가 함께합니다.
      </div>
     </section>
     <section className="contect-bottom">
      <div
       className="part color-box2"
       style={{ fontSize: "20px", fontWeight: "bold" }}
      >
       참여 및 후원 문의
      </div>
      <div className="part" style={{ fontSize: "20px" }}>
       <strong>Phone : 010-3366-0817</strong>
       <br />
       <strong>농협 301-0301-5175-31 스페셜 스페이스</strong>
       <br />
       <strong>E-mail : specialspacekorea@gmail.com</strong>
      </div>
     </section>
    </div>
   </div>
  );
 }
}

export default Contect;
