import React, { Component } from "react";
import Footer from "../../../layout/Footer";

class OrganizationChart extends Component {
  render() {
    return (
      <div className="history02 footer-parent">
        <div className="title">
          <h2>조직도</h2>
        </div>
        <div className="contents">
          <section>
            <img src="assets/arch.png" />
          </section>
        </div>
      </div>
    );
  }
}

export default OrganizationChart;
