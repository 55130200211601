import React, { Component } from "react";
import Footer from "../../../layout/Footer";

class Establish extends Component {
 render() {
  return (
   <div className="about03 footer-parent">
    <div className="title">
     <h2>설립 목적</h2>
    </div>
    <div className="contents">
     <section>
      <div className="part">
       <img src="assets/ci_w300.png" alt="" />
      </div>
      <div className="part">
       <p>
        청년세대가 맞이하는 더 나은 사회를 꿈꾸며,
        <br />
        청년이 만들어가는 사회적 가치를 연구하는 비영리단체를 운영하고 있습니다.
       </p>
       <p>
        청년 삶의 질, 사회적 약자의 삶의 질, 세대 차이, 사회적 가치 및 공익활동,
        창업 및 일자리 이슈 등을
        <br />
        주요 키워드로 활용하여,
        <br />
        미래 사회의 변화에 따라 발생 가능한 문제가 무엇인지를
        <br />
        탐구하고 해결 방안을 마련하는데 관심을 갖고 활동하고 있습니다. <br />
       </p>
       <p>
        특히, 변화하는 미래에 대응하는 과정에서
        <br />
        사회적 가치도 함께 실현할 수 있는 대안을 찾고 연구할 수<br />
        있도록 특별한 공간 [청년연구단체 ‘스페셜 스페이스’] 설립했습니다.
       </p>
       <p>
        스페셜 스페이스를 통해 청년과 함께하는 우리 사회가
        <br />더 밝아지고 발전하는데 토대가 되기를 소망합니다.
       </p>
      </div>
     </section>
    </div>
   </div>
  );
 }
}

export default Establish;
