import React, { Component } from "react";
import { FullPage, Slide } from "react-full-page";
import Footer from "../../layout/Footer";
import Establish from "./views/Establish";
import Introduction from "./views/Introduction";
import IntroduceCeo from "./views/IntroduceCeo";
import Vision from "./views/Vision";

class IntroMain extends Component {
 render() {
  return (
   <div className="full-page-area about">
    <Vision />
    <Introduction />
    <IntroduceCeo />
    <Establish />
   </div>
   // <div className="full-page-area about">
   //   <FullPage controls>
   //     <Slide>
   //       <Vision />
   //     </Slide>
   //     <Slide>
   //       <Introduction />
   //     </Slide>
   //     <Slide>
   //       <Establish />

   //     </Slide>
   //   </FullPage>
   // </div>
  );
 }
}

export default IntroMain;
