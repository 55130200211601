import React, { Component } from "react";
import { FullPage, Slide } from "react-full-page";
import Footer from "../../layout/Footer";
import HowToMake from "./views/HowToMake";
import SocialValue from "./views/SocialValue";
import SocialValue2 from "./views/SocialValue2";

class ActivityAreaMain extends Component {
  render() {
    return (
      <div className="">
        <HowToMake />
        <SocialValue />
        {/* <FullPage controls>
          <Slide>
            <HowToMake />
          </Slide>
          <Slide>
            <SocialValue />
          </Slide>
          <Slide>
            <SocialValue2 />
          </Slide>
        </FullPage> */}
      </div>
    );
  }
}

export default ActivityAreaMain;
