import React, { Component } from "react";

class Vision extends Component {
 render() {
  return (
   <div className="about01">
    <div className="title">
     <h2>비전과 미션</h2>
    </div>

    <div className="contents">
     <section>
      <div className="sub-title">
       <strong>MAKE IT BETTER ‘SPACE’</strong>
      </div>
      <div className="part ">
       청년세대의 미래와 더 나은 삶, 건강한 사회를 만들기위해
       <br />
       긍정적인 변화를 만들고자 연구 및 프로젝트를 수행하는 비영리 단체입니다.
       <br />
       <br /> 특히, 청년들이 당면한 일자리, 창업, 지역소멸, 저출생 등의 이슈와
       관련하여
       <br /> 목소리를 담아내고 정책을 제안하는 연구를 수행하고 있습니다.
      </div>
      <div className="part special">
       누구나 특별할 수 있는 공간,
       <br /> 누구나 특별함을 만들 수 있는 공간
       <br />
       '스페셜 스페이스' 로부터 시작합니다
      </div>
      <div className="part">
       <img src="assets/bg_about01.png" alt="" />
      </div>
     </section>
    </div>
   </div>
  );
 }
}

export default Vision;
