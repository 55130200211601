import React, { Component } from "react";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import MenuButton from "./MenuButton";
import MenuContainer from "./MenuContainer";
class Head extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // anchorEl:null,
      open: false,
      selectedMenu: "dashboard",
      visible: false,
      mSelectionMenuName : "소개",
    };
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentWillUpdate(prevProps, prevState) {
    var menu = prevProps.path;
    if (this.props.path !== prevProps.path) {
      if (menu.indexOf("/intro") >= 0) {
        this.setState({ selectedMenu: "intro" });
      } else if (menu.indexOf("/activiy") >= 0) {
        this.setState({ selectedMenu: "activiy" });
      } else if (menu.indexOf("/activity_area") >= 0) {
        this.setState({ selectedMenu: "activity_area" });
      } else if (menu.indexOf("/contect") >= 0) {
        this.setState({ selectedMenu: "contect" });
      }
    }
  }

  componentWillMount() {
    var menu = window.location.pathname;
    if (menu.indexOf("/intro") >= 0) {
      this.setState({ selectedMenu: "intro" });
    } else if (menu.indexOf("/activiy") >= 0) {
      this.setState({ selectedMenu: "activiy" });
    } else if (menu.indexOf("/activity_area") >= 0) {
      this.setState({ selectedMenu: "activity_area" });
    } else if (menu.indexOf("/contect") >= 0) {
      this.setState({ selectedMenu: "contect" });
    }
  }

  onSelectMenu = (e) => {
    window.scrollTo(0, 0);
    this.setState({ selectedMenu: e.currentTarget.id });
  };

  handleMouseDown(e) {
    this.toggleMenu();
    e.stopPropagation();
  }

  toggleMenu() {
    console.log(this.state.visible);
    this.setState({
      visible: !this.state.visible,
    });
  }

  selectedMenuName = (menu) => {
    this.setState({
      mSelectionMenuName : menu
    })
  }

  render() {
    return (
      <div class="navi">
        <h1>
          <a href="/">special space</a>
        </h1>
        <div className="gnb motion">
          <ul>
            <li
              id="intro"
              className={"main-menu " + this.state.selectedMenu}
              onClick={this.onSelectMenu}
            >
              <Link className="depth1" to="/intro">
                소개
              </Link>
            </li>
            <li
              id="activiy"
              className={"main-menu " + this.state.selectedMenu}
              onClick={this.onSelectMenu}
            >
              <Link className="depth1" to="/activity">
                주요활동
              </Link>
            </li>
            <li
              id="activity_area"
              className={"main-menu " + this.state.selectedMenu}
              onClick={this.onSelectMenu}
            >
              <Link className="depth1" to="/activity_area">
                활동영역
              </Link>
            </li>

            <li
              id="contect"
              className={"main-menu " + this.state.selectedMenu}
              onClick={this.onSelectMenu}
            >
              <Link className="depth1" to="/contect">
                연락문의
              </Link>
            </li>
          </ul>
        </div>
        <div className= "m-gnb-name">
          {this.state.mSelectionMenuName}
        </div>
        
        <MenuButton handleMouseDown={this.handleMouseDown} />
        
          <Menu
            menuVisibility={this.state.visible}
            toggleMenu={this.toggleMenu}
            selectedMenuName = {this.selectedMenuName}
          />
        {/* <MenuContainer visible={this.state.visible}/> */}
      </div>
    );
  }
}

export default Head;
