import React, { Component } from "react";

class Footer extends Component {
 render() {
  return (
   <div className={"footer " + this.props.position}>
    <div>
     <img src="assets/ci_w200_footer.png" alt="" />
    </div>
    <div>
     <ul>
      <li>주소 : 세종특별자치시 갈매로 351 6층 6145호(어진동, 에비뉴힐)</li>
      <li>대표전화 : 010-3366-0817 | 메일 : specialspacekorea@gmail.com</li>
      <li>비영리단체 스페셜 스페이스 등록번호 122-82-84892</li>
      <li>All Contents copyright SpecialSpace</li>
     </ul>
    </div>
   </div>
  );
 }
}

export default Footer;
