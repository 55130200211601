import React, { Component } from 'react';
import { FullPage, Slide } from "react-full-page";
import Footer from '../../layout/Footer';
import Contect from './views/Contect';

class ContectMain extends Component {

  
  render() {
    return (
      <div className="full-page-area">
       <Contect/>
      {/* <FullPage controls>
        <Slide>
          <Contect/>
        </Slide>
      </FullPage> */}
    </div>
    );
  }
}

export default ContectMain;