import React, { Component } from "react";
// import { Redirect } from "react-router-dom";
import Contents from "./layout/Contents";
import Footer from "./layout/Footer";
import Head from "./layout/Head";
import { Route, Routes, Navigate } from "react-router-dom";
import ActivityMain from "./contents/activity/ActivityMain";
import ContectMain from "./contents/contect/ContectMain";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: undefined,
      windowWidth: undefined,
    };
  }

  handleResize = () =>
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  render() {
    return (
      <div>
        <div className="wrapper" >
          <header>
            <Head />
          </header>
          <content>
            <Contents/>
          </content>  
          {/* <footer>
            <Footer/>
          </footer> */}
        </div>
        
      </div>
    );
  }
}

export default Main;
