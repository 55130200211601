import React, { Component } from "react";

class HowToMake extends Component {
  render() {
    return (
      <div className="activity-area01 h100">
        <div className="title">
          <h2>어떻게 만들어 나가는가</h2>
        </div>
        <div className="contents">
          <section>
            <div className="sub-title">
              <strong>How to make it!</strong>
            </div>
            <div className="box-section">
              <ul>
                <li>
                  <div class="box">
                    <div class="box_img">
                      <img src="assets/how_01.png" alt="" />
                    </div>
                    <div class="box_txt">
                      <p>
                        <strong>01 프로젝트 수행 유형</strong>
                      </p>
                      연구 및 세미나
                      <br />
                      사업 및 프로그램 운영
                      <br />
                      사회혁신 캠페인
                      <br />
                      공익활동 전개
                    </div>
                  </div>
                </li>
                <li>
                  <div class="box">
                    <div class="box_img">
                      <img src="assets/how_02.png" alt="" />
                    </div>
                    <div class="box_txt">
                      <p>
                        <strong> 02 단체 역할</strong>
                      </p>
                      프로젝트 크리에이터
                      <br />
                      사회 인식 개선 확산러
                      <br />
                      사회혁신 캠페인
                      <br />
                      홍보·기획 등 프로젝트 운영사
                      <br />
                      (중개 에이전시, 대행업무 등)
                    </div>
                  </div>
                </li>
                <li>
                  <div class="box">
                    <div class="box_img">
                      <img src="assets/how_03.png" alt="" />
                    </div>
                    <div class="box_txt">
                      <p>
                        <strong> 03 프로젝트 참여대상</strong>
                      </p>
                      사회적 가치 창출 목적의 프로젝트라면….
                      <br />
                      좁은 대상: 우리 사회의 어려움을 겪고 있는 대상 누구나
                      <br />
                      넒은 대상: 참여 가능한 우리 사회의 전 구성원
                    </div>
                  </div>
                </li>
                <li>
                  <div class="box">
                    <div class="box_img">
                      <img src="assets/how_04.png" alt="" />
                    </div>
                    <div class="box_txt">
                      <p>
                        <strong>04 운영 형태</strong>
                      </p>
                      외부 프로그램 운영 : 사회적 가치를 수행하고자 하는
                      <br /> 외부 기관들과의 협업을 통해
                      <br /> 홍보 및 기획
                      <br />
                      자체내 프로젝트 수행 : 인식 변화를 유도하여 <br /> 새롭게
                      형성된 긍정적인 문화가 <br /> 올바르게 정착되고 확산될 수
                      있도록 상생협력 프로젝트 <br /> 기획 및 운영
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default HowToMake;
