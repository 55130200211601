import React, { Component } from "react";
import { Link } from "react-router-dom";

class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibility: false,
    };
  }

  componentWillUpdate(prevProps, prevState) {
    if (this.props.menuVisibility !== prevProps.menuVisibility) {
      this.setState({
        visibility: prevProps.menuVisibility,
      });
    }
  }

  onClick = () => {
    this.setState({ visibility: false });
    this.props.toggleMenu();
  };

  onSelectMenu = (menu) => {
    window.scrollTo(0, 0);
    this.props.selectedMenuName(menu);
    this.onClick();
  };

  render() {
    // console.log(this.props.menuVisibility)
    // if (this.props.menuVisibility) {
    //   this.setState({visibility : this.props.menuVisibility});
    // }

    return (
      <div>
        <div
          className={
            this.state.visibility
              ? " side-menu-wrap show"
              : "side-menu-wrap hide"
          }
        ></div>
        <div
          id="flyoutMenu"
          className={this.state.visibility ? "show" : "hide"}
        >
          <div
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
              cursor: "pointer",
              padding: "20px",
            }}
            onClick={this.onClick}
          >
            <img src="assets/btn_close.png" alt="" style={{ width: "16px" }} />
          </div>
          <h2>
            <Link
              to="/intro"
              onClick={() => {
                this.onSelectMenu("소개");
              }}
            >
              소개
            </Link>
          </h2>
          <h2>
            <Link
              to="/activity"
              onClick={() => {
                this.onSelectMenu("주요활동");
              }}
            >
              주요활동
            </Link>
          </h2>
          <h2>
            <Link
              to="/activity_area"
              onClick={() => {
                this.onSelectMenu("활동영역");
              }}
            >
              활동영역
            </Link>
          </h2>
          <h2>
            <Link
              to="/contect"
              onClick={() => {
                this.onSelectMenu("연락문의");
              }}
            >
              연락문의
            </Link>
          </h2>
        </div>
      </div>
    );
  }
}

export default Menu;
