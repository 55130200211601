import React, { Component } from "react";

class MenuButton extends Component {
  render() {
    return (
      <div id="roundButton" onMouseDown={this.props.handleMouseDown}>
        <img src="assets/btn_nav_open.png" alt="" />
      </div>
    );
  }
}

export default MenuButton;
