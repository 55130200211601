import React, { Component } from 'react';
import Footer from '../../../layout/Footer';

class SocialValue2 extends Component {
  render() {
    return (
      <div className="activity-area01 footer-parent h100">
        <div className="title">
          <h2>실험 프로젝트의 사회적 가치 실현</h2>
        </div>
        <div className="contents">
          <section>
            <img src="assets/activity_area_01.png" style={{width:"145vh"}}/>
          </section>
        </div>
      </div>
    );
  }
}

export default SocialValue2;