import React, { Component } from "react";
import Footer from "../../../layout/Footer";

class SocialValue extends Component {
  render() {
    return (
      <div className="activity-area02">
        <div className="title">
          <h2>실험 프로젝트의 사회적 가치 실현</h2>
        </div>
        <div className="contents">
          <section>
            <div className="part color-box">
              실험 성격의 다양한 프로젝트 수행을 통한 사회적 가치 실현 ‘인식
              개선 유도, 사회 문제 해결, 새로운 문화 주도’
            </div>

            <div className="part">
              <p>
                ‘스페셜 스페이스’는 더 나은 청년 세대의 미래와 건강한 사회를
                만들기 위해
                <br />
                긍정적인 변화를 만들고자 연구 및 프로젝트를 수행하는 비영리
                단체입니다.
              </p>
              <p>
                핀란드어로 ‘코케일룬 파이카’라는 말은 ‘실험 장소’를 뜻합니다.
                <br /> 이 곳에서 이루어지는 것은 정책 아이디어를 제안하고 이를
                활성화시키는 플랫폼입니다.
              </p>
              <p>
                ‘스페셜 스페이스’는 사회적 가치를 실현하기 위하여 다양한
                프로젝트를 전개해 나감으로써
                <br /> 다양한 실험적 성격의 프로젝트(캠페인, 무브먼트, 연구
                등)를
                <br />① 기획하고 ② 운영하고 ③ 확산하고 ④ 활성화시켜 새로운
                문화를 주도하는 역할을 수행합니다.
              </p>
            </div>

            <div className="part color-box2">
              문제 인식의 공감대 형성 → 사회 문제 해결 동참 → 긍정적 인식 확산 →
              아름다운 문화 가치 창출
            </div>
          </section>
          <section>
            <img src="assets/activity_area_01.png"/>
          </section>
        </div>
        
      </div>
    );
  }
}

export default SocialValue;
